<template>
<div class="medical-certificate">
    <div class="container-header">
            <h3>Atestados Médicos</h3>
            <div class="chevron" @click="handleOpen()" v-bind:class="{ rotateUp: !open, rotateDown: open }">
                <ChevronUp />
            </div>
        </div>
    
    <div v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
        <div class="has-certificate" v-if="hasCertificate">
            <span >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="4" fill="#D9DFF2"/>
                <path d="M6 12L10 16L19 7" stroke="#A4B1DF"/>
                </svg>
            </span> 
            <p>Atestado</p>
        </div>

        <div class="certificate-table">
            <div>
                <h5>CPF</h5>
                <p>{{ cpfPaciente }}</p>
            </div>

            <div>
                <h5>Período</h5>
                <p>{{ days }}</p>
            </div>
            
            <div v-if="afastamento?.length">
                <h5>Afastamento</h5>
                <p>{{ afastamento.join(', ') }}</p>
            </div>

            <div>
                <h5>CID</h5>
                <p>{{ cid }}</p>
            </div>
        </div>

        <div class="certificate-table">
            <div v-if="maisInformacoes">
                <h5>Mais informações</h5>
                <p>{{ maisInformacoes }}</p>
            </div>
        </div>

        <div class="has-certificate" v-if="hasCompanionAttendanceDeclaration">
            <span >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="4" fill="#D9DFF2"/>
                <path d="M6 12L10 16L19 7" stroke="#A4B1DF"/>
                </svg>
        </span> 
        <p> Declaração de Comparecimento </p>
        </div>

        <div class="certificate-table">
            <div v-if="horarioChegada">
                <h5>Horário de chegada</h5>
                <p>{{ horarioChegada }}</p>
            </div>

            <div v-if="horarioSaida">
                <h5>Horário de saída</h5>
                <p>{{ horarioSaida }}</p>
            </div>
        </div>

        <div class="has-certificate" v-if="hasCompanionAttendanceDeclaration">
            <span >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="24" height="24" rx="4" fill="#D9DFF2"/>
                <path d="M6 12L10 16L19 7" stroke="#A4B1DF"/>
                </svg>
        </span> 
        <p> Declaração de Comparecimento de Acompanhante</p>
        </div>

        <div class="certificate-table">
            <div v-if="companionName">
                <h5>Nome do Acompanhante</h5>
                <p>{{ companionName }}</p>
            </div>

            <div v-if="companionDocument">
                <h5>CPF</h5>
                <p>{{ companionDocument }}</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>

import ChevronUp from '@/assets/icons/chevron-up.svg'

export default {
    components: { ChevronUp },

    props: {
        hasCertificate: Boolean,
        companionName: String,
        companionDocument: String,
        hasAttendanceDeclaration: Boolean,
        hasCompanionAttendanceDeclaration: Boolean,
        days: String || Number,
        cid: String,
        horarioChegada: String,
        horarioSaida: String,
        cpfPaciente: String,
        maisInformacoes: String,
        afastamento: Array,
    },

    data() {
      return{
        open: true,
      }
    },

    methods: {
      handleOpen(){
        this.open = !this.open
      }
    },
}
</script>

<style lang="scss" scoped>
.medical-certificate {
    display: flex;
    flex-direction: column;

    .container-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-right: 30px;

            h3 {
            font-size: 18px;
            color: var(--dark-blue);
            font-weight: 600;
            }

            .chevron {
            width: 25px;
            height: 25px;

            > svg {
                cursor: pointer;
                stroke: var(--neutral-600);
                transition: .5s;
            }
            }

            .rotateUp {
                animation: rotateUp .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            .rotateDown {
                animation: rotateDown .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            @keyframes rotateUp {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(180deg);
            }
            }

            @keyframes rotateDown {
            0% {
                transform: rotate(180deg);
            }
            100% {
                transform: rotate(0deg);
            }
            }

        }

    .has-certificate {
        display: flex;
        flex-direction: row;
        margin: 20px 0;
        span {
            margin-right: 10px;
        }
    }

    .certificate-table {
        display: flex;
        flex-direction: row;
        margin: 20px 0;

        > div {
            display: flex;
            flex-direction: column;
            margin-right: 40px;

            h5 {
                color: var(--dark-blue);
                font-weight: 600;
                font-size: 16px;
            }
        }
    }
}
</style>